import { Images } from '~data'
const featureSectionData = {
    featureTabTitle: "",
    tabWidget: [
        {
            icon: Images.projectManagement.featureTwoIcon1,
            iconBackground: "#ff8d52",
            title: "Road Map",
        },
        {
            icon: Images.projectManagement.featureTwoIcon2,
            iconBackground: "#15cda8",
            title: "Whitepaper",
        },
        {
            icon: Images.projectManagement.featureTwoIcon1,
            iconBackground: "#ff8d52",
            title: "Technologies",
        },
        {
            icon: Images.projectManagement.featureTwoIcon2,
            iconBackground: "#15cda8",
            title: "Investment Guide",
        },
    ],


}

export default featureSectionData;