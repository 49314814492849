
import React from 'react'
import { Tab, Nav, Container, Col, Row } from "react-bootstrap";
import FeatureTabPenLayout from './Components/FeatureTabPenLayout'

import Feature from './style'
import featureSectionData from '~data/project/features'

import FeatureWidgetsBlock1 from './Components/1/FeatureWidgetsBlock'
import FeatureImage1 from './Components/1/FeatureImages'
import featureSectionData1 from './Components/1/data'


import FeatureWidgetsBlock2 from './Components/2/FeatureWidgetsBlock'
import FeatureImage2 from './Components/2/FeatureImages'
import featureSectionData2 from './Components/2/data'


import FeatureWidgetsBlock3 from './Components/3/FeatureWidgetsBlock'
import FeatureImage3 from './Components/3/FeatureImages'
import featureSectionData3 from './Components/3/data'


export default function FeatureSection() {
  return (
    <Feature background="#fff">
      <Container className="container">
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="first"
        >
          <Feature.Tab>
            <Row className="justify-content-center">
              <Col className="col-xl-7 col-lg-8 col-md-10">
                <Nav className="nav nav-tabs" id="myTab" role="tablist">
                  <Nav.Link
                    eventKey="first"
                    className="nav-link"
                  >ICO website
                  </Nav.Link>

                  <Nav.Link
                    eventKey="second"
                    className="nav-link"
                  >Dpassive.finance Trading
                  </Nav.Link>
                  <Nav.Link
                    eventKey="third"
                    className="nav-link"
                  >Assert Management
                  </Nav.Link>
                </Nav>
              </Col>
            </Row>
            <Tab.Content className="tab-content">
              <Tab.Pane eventKey="first">
                <FeatureTabPenLayout
                  imageBlock={<FeatureImage1 />}
                  contentBlock={<FeatureWidgetsBlock1 widgetData={featureSectionData1.tabWidget} />}
                  contentBlockTitle={featureSectionData1.featureTabTitle}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <FeatureTabPenLayout
                  imageBlock={<FeatureImage2 />}
                  contentBlock={<FeatureWidgetsBlock2 widgetData={featureSectionData2.tabWidget} />}
                  contentBlockTitle={featureSectionData2.featureTabTitle}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <FeatureTabPenLayout
                  imageBlock={<FeatureImage3 />}
                  contentBlock={<FeatureWidgetsBlock3 widgetData={featureSectionData3.tabWidget} />}
                  contentBlockTitle={featureSectionData3.featureTabTitle}
                />
              </Tab.Pane>
            </Tab.Content>
          </Feature.Tab>
        </Tab.Container>
      </Container>
    </Feature>
  )
}